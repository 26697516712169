<template>
  <div class="card-xl-stretch mb-xl-8 card">
    <div class="card-body">
      <div class="d-flex flex-stack">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
            <a
              href="javascript:void(0)"
              class="text-dark fw-bolder text-hover-primary fs-5"
            >
              {{ title }}
            </a>
            <span class="text-muted fw-bold">
              {{ subtitle }}
            </span>
          </div>
        </div>

        <div class="ms-1 dropdown" v-if="updatePermission || deletePermission">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary dropdown-toggle"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg
                src="media/icons/duotone/Layout/Layout-4-blocks-2.svg"
              />
            </span>
          </button>
          <ul
            class="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px"
            aria-labelledby="dropdownMenuButton1"
          >
            <li v-if="updatePermission" class="menu-item px-3">
              <div
                class="dropdown-item menu-content fs-6 text-info fw-bolder px-3 py-4"
                @click="$emit('editElement', item.id)"
              >
                {{ $t("btnEdit") }}
              </div>
            </li>
            <li v-if="deletePermission" class="menu-item px-3">
              <div
                class="dropdown-item menu-content fs-6 text-danger fw-bolder px-3 py-4"
                @click="$emit('deleteElement', item.id)"
              >
                {{ $t("btnDelete") }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-p pt-5 bg-white flex-grow-1">
        <div class="row g-0 mt-4">
          <!--begin::Col-->
          <div
            class="col-6 mr-4"
            v-for="(value, key) in item.items"
            :key="key.id"
          >
            <!--begin::Label-->
            <div class="fs-7 text-muted fw-bold">{{ key }}</div>
            <!--end::Label-->

            <!--begin::Stat-->
            <div class="fs-4 fw-bolder">{{ value }}</div>
            <!--end::Stat-->
          </div>
          <!--end::Col-->
        </div>
      </div>
      <div class="d-flex flex-center flex-column">
        <button
            class="btn btn-sm btn-light-primary fw-bolder py-2 mx-2 px-3 mb-2"
            @click="$emit('seeDetail', item.id)"
        >
          {{ $t("btnDetails") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "cardCompany",
  props: {
    item: Object,
    title: String,
    subtitle: String,
    updatePermission: Boolean,
    deletePermission: Boolean
  }
});
</script>


import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import CardBasic from "@/components/cards/CardBasic.vue";
import MaritimeFileTypeSelect from "@/components/catalogs-select/MaritimeFileTypeSelect.vue";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import PortsSelect from "@/components/catalogs-select/PortsSelect.vue";
import ShipsSelect from "@/components/catalogs-select/ShipsSelect.vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
import ContactSelect from "@/components/catalogs-select/ContactSelect.vue";
import MaritimeServicesSelect from "@/components/catalogs-select/MaritimeServicesSelect.vue";
import OperationTypesSelect from "@/components/catalogs-select/OperationTypesSelect.vue";
import TableBasic from "@/components/tables/TableBasic.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Docks",
  components: {
    CardBasic,
    MaritimeFileTypeSelect,
    OfficeSelect,
    PortsSelect,
    ShipsSelect,
    EntitySelect,
    ContactSelect,
    MaritimeServicesSelect,
    OperationTypesSelect,
    TableBasic,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const search = ref("");
    const dataList = ref([]);
    const filtered = ref([]);
    const file_type_id = ref("");
    const office_id = ref("");
    const port_id = ref("");
    const vessel_id = ref("");
    const ship_consignee_id = ref("");
    const customer_id = ref("");
    const contact_id = ref("");
    const check_in_id = ref("");
    const check_out_id = ref("");
    const operation_id = ref("");
    const prefix = ref("");
    const calls = ref("");
    const importt = ref("");
    const exportt = ref("");
    const viewType = ref("card");
    const tableButtons = ref([]);
    const originalData = ref([]);

    const header = ref([
      { column: t("icode"), key: "code" },
      { column: t("ioffices"), key: "office_id" },
      { column: t("iport"), key: "port_id" },
      { column: t("ivessel"), key: "vessel_id" },
    ]);

    const createFieldItems = (data) => {
      const add_fields = data.map(function (element) {
        element.items = {
          "Tipo de expediente": element.type.name,
          Puerto: element.port.name,
          Buque: element.vessel.name,
          "Consignatario de buque":
            element.ship_consignee != null ? element.ship_consignee.name : "",
          Cliente: element.customer != null ? element.customer.name : "",
          "Contacto de cliente":
            element.contact != null ? element.contact.name : "",
        };
        return element;
      });
      return add_fields;
    };

    const getItems = () => {
      ApiService.get("/api/maritimefiles").then(({ data }) => {
        originalData.value = data.maritime_files;
        dataList.value = createFieldItems(data.maritime_files);
        filtered.value = dataList.value;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
      });
    };

    onMounted(() => {
      getItems();
    });

    const serverSearch = () => {
      const params = `file_type_id=${file_type_id.value}&office_id=${office_id.value}&port_id=${port_id.value}&vessel_id=${vessel_id.value}&ship_consignee_id=${ship_consignee_id.value}&customer_id=${customer_id.value}&contact_id=${contact_id.value}&check_in_id=${check_in_id.value}&check_out_id=${check_out_id.value}&operation_id=${operation_id.value}&prefix=${prefix.value}&calls=${calls.value}&importt=${importt.value}&exportt=${exportt.value}`;
      ApiService.get(`/api/maritimefiles?${params}`).then(({ data }) => {
        originalData.value = data.maritime_files;
        dataList.value = createFieldItems(data.maritime_files);
        filtered.value =
          viewType.value === "card"
            ? dataList.value
            : createTableItems(originalData.value);
        file_type_id.value = "";
        office_id.value = "";
        port_id.value = "";
        vessel_id.value = "";
        ship_consignee_id.value = "";
        customer_id.value = "";
        contact_id.value = "";
        check_in_id.value = "";
        check_out_id.value = "";
        operation_id.value = "";
        prefix.value = "";
        calls.value = "";
        importt.value = "";
        exportt.value = "";
      });
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const createElement = () => {
      router.push({ name: "maritimefileCreate" });
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          code: element.code,
          office_id: element.office.name,
          port_id: element.port.name,
          vessel_id: element.vessel.name,
        });
      });
      return result;
    };

    watch(
      () => viewType.value,
      (first) => {
        if (first) {
          filtered.value =
            first === "card"
              ? createFieldItems(originalData.value)
              : createTableItems(originalData.value);
        }
      }
    );

    const actionsButton = (itemId) => {
      router.push({ name: "maritimefileEdit", params: { id: itemId } });
    };

    return {
      filtered,
      dataList,
      search,
      file_type_id,
      office_id,
      port_id,
      vessel_id,
      ship_consignee_id,
      customer_id,
      contact_id,
      check_in_id,
      check_out_id,
      operation_id,
      prefix,
      calls,
      importt,
      exportt,
      viewType,
      header,
      tableButtons,
      onSearch,
      serverSearch,
      createElement,
      actionsButton,
    };
  },
});
